<template>
  <div>
    <div class="row mb-6">
      <div class="col-lg-2 mb-lg-0">
        <label>Mã giao dịch:</label>
        <input type="text" class="form-control datatable-input" disabled />
      </div>
      <div class="col-lg-2 mb-lg-0">
        <label>Mã thanh toán:</label>
        <input
          type="text"
          v-model="paramFilter.keyword"
          class="form-control datatable-input"
        />
      </div>
      <div class="col-lg-2 mb-lg-0">
        <label>Số điện thoại:</label>
        <input
          type="text"
          v-model="paramFilter.phone_from"
          class="form-control datatable-input"
        />
      </div>
      <div class="col-lg-2 mb-lg-0">
        <label>Nội dung:</label>
        <input
            type="text"
            disabled
            class="form-control datatable-input"
        />
      </div>
      <div class="col-lg-2 mb-lg-0">
        <label>Mã coupon:</label>
        <input
            type="text"
            disabled
            class="form-control datatable-input"
        />
      </div>
      <div class="col-lg-2 mb-lg-0">
        <label>Loại GD:</label>
        <multiselect
          v-model="selected_types"
          :options="types"
          :multiple="true"
          label="text"
          track-by="value"
          value="value"
        ></multiselect>
      </div>
    </div>
    <div class="row mb-8">
      <div class="col-lg-2 mb-lg-0 mb-6">
        <label>Phương thức thanh toán:</label>
        <select
          class="form-control datatable-input"
          data-col-index="6"
          disabled
        >
          <option value="">Tất cả</option>
          <option value="wallet">Ví</option>
          <option value="ATM_CARD">Thẻ nội địa qua Cổng</option>
          <option value="CREDIT_CARD">Thẻ quốc tế qua Cổng</option>
        </select>
      </div>
      <div class="col-lg-2 mb-lg-0 mb-6">
        <label>Trạng thái:</label>
        <multiselect
          v-model="selected_status"
          :options="status"
          :multiple="true"
          label="text"
          track-by="value"
          value="value"
        ></multiselect>
      </div>
      <div class="col-lg-2 mb-lg-0 mb-6">
        <label>Trạng thái hoá đơn:</label>
        <select
          class="form-control datatable-input"
          data-col-index="7"
          disabled
        >
          <option value="">Tất cả</option>
          <option value="create">Create</option>
          <option value="created">Created</option>
          <option value="success">Success</option>
          <option value="fail">Fail</option>
          <option value="payment_cancel">Payment cancel</option>
          <option value="payment_pending">Payment pending</option>
          <option value="payment_success">Payment success</option>
          <option value="expired_session">Expired session</option>
          <option value="processing">Processing</option>
          <option value="wait_to_review">Wait to review</option>
          <option value="reject">Reject</option>
          <option value="cancel">Cancel</option>
        </select>
      </div>
      <div class="col-lg-4 mb-lg-0 mb-6">
        <label>Ngày tạo:</label>
        <div class="input-daterange input-group" id="kt_datepicker">
          <input
            type="date"
            v-model="paramFilter.date_from"
            class="form-control datatable-input"
            name="start"
            placeholder="Từ"
            @input="handleInput"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="la la-ellipsis-h"></i>
            </span>
          </div>
          <input
            type="date"
            v-model="paramFilter.date_to"
            class="form-control datatable-input"
            name="end"
            placeholder="Đến"
            @input="handleInput"
          />
        </div>
      </div>
      <div class="col-lg-2 mb-lg-0 mb-6 d-flex align-items-start p-5 mt-7">
        <b-form-checkbox
          id="searchByV1"
          v-model="searchByV1"
          name="searchByV1"
          :value="this.searchByV1"
          :unchecked-value="!this.searchByV1"
        >
          Tìm kiếm theo mã thanh toán
        </b-form-checkbox>
      </div>
    </div>
    <div class="row mt-8">
      <div class="col-lg-12 text-right">
        <button
          class="btn btn-primary btn-primary--icon"
          @click="createLinkSearch()"
        >
          <span>
            <i class="la la-search"></i>
            <span>Tìm kiếm</span>
          </span>
        </button>
        <b-button
            class="mr-2"
            variant="outline-primary"
            @click="$bvModal.show('export-desc')"
        >Xuất excel</b-button
        >&nbsp;
      </div>
    </div>
    <b-modal id="export-desc" title="Yêu cầu xuất dữ liệu">
      <br />
      <div class="d-block text-center">
        <b-form-textarea
            placeholder="Nhập mô tả yêu cầu xuất dữ liệu ..."
            v-model="paramFilter.exportDesc"
            rows="3"
        ></b-form-textarea>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()"> Hủy </b-button>
        <b-button size="sm" variant="success" @click="exportData()">
          Xuất
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import router from "../../../../router";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const CmsRepository = RepositoryFactory.get("cms");
export default {
  mixins: [Common],
  name: "filterTransaction",
  props: ["paramFilter", "getList", "resetCurrent1", "currentPage1"],
  data() {
    return {
      filter: {},
      searchByV1: true,
      selected_types: [],
      selected_types_str: "",
      types: [
        {value: 1, text: 'Nạp tiền'},
        {value: 2, text: 'Chuyển tiền'},
        {value: 3, text: 'Rút tiền'},
        {value: 4, text: 'Thanh toán ví'},
        {value: 5, text: 'Giao dịch đảo'},
        {value: 6, text: 'Nạp tiền qua chuyển khoản'},
        {value: 8, text: 'Hoàn tiền một phần'},
        {value: 9, text: 'Ưu đãi hoàn tiền'},
        {value: 10, text: 'Chuyển tiền Lì xì'},
        {value: 11, text: 'Chuyển tiền ngân hàng'},
        {value: 12, text: 'Donate'},
        {value: 13, text: 'Tiền thưởng giới thiệu'},
        {value: 15, text: 'Giao dịch bù ví'},
        {value: 16, text: 'Chi hộ'},
        {value: 17, text: 'Nạp tiền chuyển khoản'},
      ],
      selected_status_str: "",
      selected_status: [],
      status: [
        {value: -1, text: 'Thất bại'},
        {value: 0, text: 'Đang chờ'},
        {value: 1, text: 'Thành công'},
        {value: 2, text: 'Giao dịch đảo'},
      ],
    };
  },
  components: {},
  mounted() {
    if (this.paramFilter.type !== undefined) {
      this.selected_types = this.filterTypes(this.paramFilter.type);
    }
  },
  watch: {
    searchByV1: function (newVal) {
      this.$emit("searchByV1", { param: newVal });
    },
    selected_types: function (newVal) {
      this.selected_types = newVal;
      this.selected_types_str = this.convertArrayToString(this.selected_types);
      this.paramFilter.type = this.selected_types_str;
    },
    selected_status: function (newVal) {
      this.selected_status = newVal;
      this.selected_status_str = this.convertArrayToString(this.selected_status);
      this.paramFilter.status = this.selected_status_str;
    },
  },
  methods: {
    filterTypes(data) {
      let selectedValues = data.split(",").map(Number);
      return this.types.filter((type) => selectedValues.includes(type.value));
    },
    createLinkSearch() {
      this.paramFilter.page = 1;
      this.filter.keyword = this.paramFilter.keyword;
      this.filter.phone_from = this.paramFilter.phone_from;
      this.filter.date_from = this.paramFilter.created_at_from;
      this.filter.date_to = this.paramFilter.created_at_to;
      this.filter.page = this.paramFilter.page;
      if (this.selected_status_str) {
        this.filter.status = this.paramFilter.status;
      }
      if (this.selected_types_str) {
        this.filter.type = this.paramFilter.type;
      }

      router
        .push({ path: "/transactions/list", query: this.filter })
        .catch(() => {});
      this.resetCurrent1();
      if (this.currentPage1 === 1) {
        this.getList();
      }
    },
    exportData() {
      let params = this.paramFilter;
      let list = { ...this.paramFilter };
      params = this.convert(list);

      if (
        this.paramFilter.exportDesc != null &&
        this.paramFilter.exportDesc !== ""
      ) {
        params.export_desc = this.paramFilter.exportDesc;
      }
      params.wallet_type = "TRANSACTION_V1";
      this.$bvModal.hide("export-desc");
      this.$bus.$emit("show-loading", true);
      CmsRepository.exportFileReconcileWallet(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.notifyAlert(
              "success",
              "Tạo tiến trình thành công. Vui lòng chờ"
            );
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.notifyAlert("warn", "Có lỗi xảy ra");
        });
    },
    convert(params) {
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] === "") {
          delete params[key];
        }
      });
      return params;
    },
    handleInput() {
      if (!this.paramFilter.created_at_from) {
        this.paramFilter.created_at_from = this.firstDayOfMonth();
      }
      if (!this.paramFilter.created_at_to) {
        this.paramFilter.created_at_to = this.lastDayOfMonth();
      }
    },
    convertArrayToString(data) {
      let valuesArray = data.map((item) => item.value);
      return valuesArray.join(",");
    },
  },
};
</script>
