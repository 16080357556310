<template>
    <div>
        <div class="row mb-6">
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Mã giao dịch:</label>
                <input
                    type="text"
                    class="form-control datatable-input"
                    v-model="paramFilter.code"
                />
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Mã thanh toán:</label>
                <input
                    type="text"
                    v-model="paramFilter.wallet_transaction_id"
                    class="form-control datatable-input"
                />
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Số điện thoại:</label>
                <input
                    type="text"
                    v-model="paramFilter.user_phone"
                    class="form-control datatable-input"
                />
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Nội dung:</label>
                <input
                    type="text"
                    v-model="paramFilter.description"
                    class="form-control datatable-input"
                />
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Mã coupon:</label>
                <input
                    type="text"
                    v-model="paramFilter.coupon_code"
                    class="form-control datatable-input"
                />
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Loại GD:</label>
                <select
                    class="form-control datatable-input"
                    data-col-index="6"
                    v-model="paramFilter.action"
                >
                    <option value="">Tất cả</option>
                    <option value="PAYMENT">PAYMENT</option>
                    <option value="CASHBACK">CASHBACK</option>
                    <option value="DEPOSIT">DEPOSIT</option>
                    <option value="DEPOSIT_TRANSFER">DEPOSIT_TRANSFER</option>
                    <option value="OFFSET">OFFSET</option>
                    <option value="REVERSE">REVERSE</option>
                    <option value="REVERSE_PARTIAL">REVERSE_PARTIAL</option>
                    <option value="TRANSFER">TRANSFER</option>
                    <option value="WITHDRAW">WITHDRAW</option>
                </select>
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Phương thức thanh toán:</label>
                <select
                    class="form-control datatable-input"
                    data-col-index="6"
                    v-model="paramFilter.transaction_type"
                >
                    <option value="">Tất cả</option>
                    <option value="wallet">Ví</option>
                    <option value="ATM_CARD">Thẻ nội địa qua Cổng</option>
                    <option value="CREDIT_CARD">Thẻ quốc tế qua Cổng</option>
                </select>
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Trạng thái:</label>
                <select
                    class="form-control datatable-input"
                    data-col-index="7"
                    v-model="paramFilter.status"
                >
                    <option value="">Tất cả</option>
                    <option value="success">Success</option>
                    <option value="fail">Fail</option>
                    <option value="pending">Pending</option>
                    <option value="create">Create</option>
                    <option value="create_success">Create success</option>
                    <option value="create_replace">Create replace</option>
                    <option value="offset">Offset</option>
                    <option value="refund">Refund</option>
                    <option value="reverse">Reverse</option>
                    <option value="wait_to_refund">Wait to refund</option>
                    <option value="cancel">Cancel</option>
                </select>
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Trạng thái hoá đơn:</label>
                <select
                    class="form-control datatable-input"
                    data-col-index="7"
                    v-model="paramFilter.order_status"
                >
                    <option value="">Tất cả</option>
                    <option value="create">Create</option>
                    <option value="created">Created</option>
                    <option value="success">Success</option>
                    <option value="fail">Fail</option>
                    <option value="payment_cancel">Payment cancel</option>
                    <option value="payment_pending">Payment pending</option>
                    <option value="payment_success">Payment success</option>
                    <option value="expired_session">Expired session</option>
                    <option value="processing">Processing</option>
                    <option value="wait_to_review">Wait to review</option>
                    <option value="reject">Reject</option>
                    <option value="cancel">Cancel</option>
                </select>
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Tìm kiếm theo ngày:</label>
                <select
                    class="form-control datatable-input"
                    data-col-index="7"
                    v-model="paramFilter.type_time"
                >
                    <option value="created_at">Ngày tạo</option>
                    <option value="payment_at">Ngày hoàn thành</option>
                </select>
            </div>
            <div class="col-lg-4 mb-lg-0 mb-6">
                <label>Từ ngày:</label>
                <div class="input-daterange input-group" id="kt_datepicker">
                    <input
                        type="date"
                        v-model="paramFilter.created_at_from"
                        class="form-control datatable-input"
                        name="start"
                        placeholder="Từ"
                        @input="handleInput"
                    />
                    <div class="input-group-append">
            <span class="input-group-text">
              <i class="la la-ellipsis-h"></i>
            </span>
                    </div>
                    <input
                        type="date"
                        v-model="paramFilter.created_at_to"
                        class="form-control datatable-input"
                        name="end"
                        placeholder="Đến"
                        @input="handleInput"
                    />
                </div>
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>SDT Nguồn:</label>
                <input
                    type="text"
                    v-model="paramFilter.phone_from"
                    class="form-control datatable-input"
                />
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>SDT Đích:</label>
                <input
                    type="text"
                    v-model="paramFilter.phone_to"
                    class="form-control datatable-input"
                />
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Giá trị GD từ:</label>
                <input
                    type="text"
                    v-model="formattedPriceFrom"
                    class="form-control datatable-input"
                />
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Giá trị GD đến:</label>
                <input
                    type="text"
                    v-model="formattedPriceTo"
                    class="form-control datatable-input"
                />
            </div>
            <div class="col-lg-2 mb-lg-0 mb-6">
                <label>Từ khóa:</label>
                <input
                    type="text"
                    class="form-control datatable-input"
                    v-model="paramFilter.keyword"
                />
            </div>
        </div>
        <div class="row mt-8">

            <div class="col-lg-12 text-right">
                <button
                    class="btn btn-primary btn-primary--icon"
                    @click="createLinkSearch()"
                >
          <span>
            <i class="la la-search"></i>
            <span>Tìm kiếm</span>
          </span>
                </button>
                <b-button
                    class="mr-2"
                    variant="outline-primary"
                    @click="$bvModal.show('export-desc')"
                >Xuất excel
                </b-button>&nbsp;
            </div>
        </div>
        <b-modal id="export-desc" title="Yêu cầu xuất dữ liệu">
            <br/>
            <div class="d-block text-center">
                <b-form-textarea
                    placeholder="Nhập mô tả yêu cầu xuất dữ liệu ..."
                    v-model="paramFilter.exportDesc"
                    rows="3"
                ></b-form-textarea>
            </div>
            <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="danger" @click="cancel()"> Hủy</b-button>
                <b-button size="sm" variant="success" @click="exportData()">
                    Xuất
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import router from "../../../../router";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const CmsRepository = RepositoryFactory.get("cms");
export default {
    mixins: [Common],
    name: "filterTransaction",
    props: ["paramFilter", "getList", "resetCurrent", "currentPage"],
    data() {
        return {
            filter: {},
            searchByV1: false,
            price_to:'',
            price_from:''
        };
    },
    components: {},
    mounted() {
    },
    computed: {
        formattedPriceFrom: {
            get() {
                return this.price_from;
            },
            set(value) {
                // Xử lý giá trị đã định dạng và lưu vào priceInput
                if(value !== ''){
                    value = value.replaceAll('.','')
                }
                const unformattedPrice = this.formatPrice(value);
                this.price_from = unformattedPrice.toString();
            },
        },
        formattedPriceTo: {
            get() {
                return this.price_to;
            },
            set(value) {
                // Xử lý giá trị đã định dạng và lưu vào priceInput
                if(value !== ''){
                    value = value.replaceAll('.','')
                }
                const unformattedPrice = this.formatPrice(value);
                this.price_to = unformattedPrice.toString();
            },
        },
    },
    watch: {
        searchByV1: function (newVal) {
            this.$emit("searchByV1", {param: newVal});
        },
        // price_to:function (newVal){
        //     if(newVal !== ''){
        //         newVal = newVal.replaceAll('.')
        //     }
        //     this.paramFilter.price_to = newVal;
        //     this.price_to = this.formatPrice(this.paramFilter.price_to);
        // },
    },
    methods: {
        formatPrice(value){
            var number = parseInt(value);
            if (isNaN(number) || number == null) {
                return 0;
            }
            var formatter = new Intl.NumberFormat("it-IT");
            return formatter.format(number);
        },
        createLinkSearch() {
            // this.filter.keyword = this.paramFilter.keyword;
            // this.filter.type_time = this.paramFilter.type_time;
            // this.filter.code = this.paramFilter.code;
            // this.filter.price_from = this.paramFilter.price_from;
            // this.filter.price_to = this.paramFilter.price_to;
            // this.filter.wallet_transaction_id = this.paramFilter.wallet_transaction_id;
            // this.filter.user_phone = this.paramFilter.user_phone;
            // this.filter.description = this.paramFilter.description;
            // this.filter.transaction_type = this.paramFilter.transaction_type;
            // this.filter.status = this.paramFilter.status;
            // this.filter.order_status = this.paramFilter.order_status ?? 'created_at';
            // this.filter.created_at_from = this.paramFilter.created_at_from;
            // this.filter.created_at_to = this.paramFilter.created_at_to;
            // this.filter.coupon_code = this.paramFilter.coupon_code;
            // this.filter.exportDesc = this.paramFilter.exportDesc;
            // this.filter.action = this.paramFilter.action;
            var value = this.price_to;
            if(value !== ''){
                value = value.replaceAll('.','')
            }
            this.paramFilter.price_to = value;

            var valueFrom = this.price_from;
            if(valueFrom !== ''){
                valueFrom = valueFrom.replaceAll('.','')
            }
            this.paramFilter.price_from = valueFrom;

            router
                .push({path: "/transactions/list", query: this.paramFilter})
                .catch(() => {
                });
            this.resetCurrent();
            if (this.currentPage === 1) {
                this.getList();
            }
        },
        exportData() {
            let params = this.paramFilter;
            let list = {...this.paramFilter};
            params = this.convert(list);

            if (this.paramFilter.exportDesc != null && this.paramFilter.exportDesc != "") {
                params.export_desc = this.paramFilter.exportDesc;
            }
            params.wallet_type = "TRANSACTION_V2";

            this.$bvModal.hide("export-desc");
            this.$bus.$emit("show-loading", true);
            CmsRepository.exportFileReconcileWallet(params)
                .then((response) => {
                    if (response.data.error_code) {
                        this.notifyAlert("warn", response.data.message);
                    } else {
                        this.notifyAlert(
                            "success",
                            "Tạo tiến trình thành công. Vui lòng chờ"
                        );
                    }
                    this.$bus.$emit("show-loading", false);
                })
                .catch(() => {
                    this.notifyAlert("warn", "Có lỗi xảy ra");
                });
        },
        convert(params) {
            Object.keys(params).forEach(key => {
                if (params[key] === null || params[key] == '') {
                    delete params[key];
                }
            });
            return params;
        },
        handleInput() {
            if (!this.paramFilter.created_at_from) {
                this.paramFilter.created_at_from = this.firstDayOfMonth();
            }
            if (!this.paramFilter.created_at_to) {
                this.paramFilter.created_at_to = this.lastDayOfMonth();
            }
        },
    },
};
</script>
