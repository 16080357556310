<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Danh sách giao dịch</h6>
              </template>
              <div class="filter-transaction" v-if="!searchByV1">
                <filterTransaction
                  :paramFilter="filter"
                  :getList="getList"
                  @searchByV1="handleSearchByV1"
                  :resetCurrent="resetCurrent"
                  :currentPage="currentPage"
                ></filterTransaction>
              </div>
                <div class="filter-transaction" v-if="searchByV1">
                    <filterTransactionV1
                        :paramFilter="filter"
                        :getList="getList"
                        @searchByV1="handleSearchByV1"
                        :resetCurrent1="resetCurrent1"
                        :currentPage1="currentPage1"
                    ></filterTransactionV1>
                </div>
              <b-row>
                <b-col> </b-col>
                <b-col> </b-col>
                <b-col>
                  <b>Tổng tiền gốc: </b> {{ numberFormat(this.totalPrice) }} VNĐ
                </b-col>
              </b-row>
              <b-row>
                <b-col> </b-col>
                <b-col> </b-col>
                <b-col>
                  <b>Tổng giá trị thanh toán: </b>
                  {{ numberFormat(this.totalAmount) }} VNĐ
                </b-col>
              </b-row>
              <b-row>
                <b-col> </b-col>
                <b-col> </b-col>
                <b-col>
                  <b>Tổng số lượng GD: </b>
                  {{ numberFormat(this.paginate.total) }} giao dịch
                </b-col>
              </b-row>
              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                <template #cell(status)="data">
                  <span>
                    <span
                      class="text-nowrap"
                      :class="getColorStatus(data.value, 'TRANSACTION')"
                      >{{ getTextStatus(data.value) }}</span
                    >
                  </span>
                </template>
                <template #cell(detail)="data">
                  <a :href="`${data.value}`">Xem</a>
                </template>
                <template #cell(price)="data">
                  {{ numberFormat(data.item.price) }}
                </template>
                <template #cell(amount)="data">
                  {{ numberFormat(data.item.amount) }}
                </template>
              </b-table>
              <div class="mt-3" v-if="!searchByV1">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
              <div class="mt-3" v-if="searchByV1">
                <b-pagination-nav
                  v-model="currentPage1"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import filterTransaction from "@/view/pages/transaction/items/filterTransaction.vue";
import filterTransactionV1 from "@/view/pages/transaction/items/filterTransactionV1.vue";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import router from "@/router";
Vue.component("multiselect", Multiselect);

const TransactionRepository = RepositoryFactory.get("transactions");

export default {
  mixins: [Common],
  data() {
    return {
      totalPrice: 0,
      totalAmount: 0,
      filter: {
          type_time:"created_at",
          status:'',
          keyword:'',
          price_from:'',
          price_to:'',
          phone_from:'',
          phone_to:'',
          code:'',
          wallet_transaction_id:'',
          user_phone:'',
          transaction_type:'',
          order_status:'',
          created_at_from:'',
          created_at_to:'',
          coupon_code:'',
          exportDesc:'',
          action:'',
      },
      fields: [
        // {
        //   key: "id",
        //   label: "ID",
        // },
        {
          key: "code",
          label: "Mã giao dịch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell"
        },
        {
          key: "wallet_transaction_id",
          label: "Mã thanh toán",
          tdClass: "datatable-cell",
          thClass: "datatable-cell"
        },
        {
          key: "coupon_code",
          label: "Mã coupon",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "price",
          label: "Giá gốc",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "amount",
          label: "Giá tiền",
          tdClass: "datatable-cell",
          thClass: "datatable-cell"
        },
        {
          key: "action",
          label: "Loại GD",
          tdClass: "datatable-cell",
          thClass: "datatable-cell"
        },
        {
          key: "user_phone",
          label: "Phone",
          tdClass: "datatable-cell",
          thClass: "datatable-cell"
        },
        {
          key: "description",
          label: "Mô tả",
          tdClass: "datatable-cell",
          thClass: "datatable-cell"
        },
        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell"
        },
        {
          key: "order_status",
          label: "Trạng thái HD",
          tdClass: "datatable-cell",
          thClass: "datatable-cell"
        },
        {
          key: "created_at",
          label: "TT tạo",
          tdClass: "datatable-cell",
          thClass: "datatable-cell"
        },
        {
          key: "completed_at",
          label: "TT thanh toán",
          tdClass: "datatable-cell",
          thClass: "datatable-cell"
        },
        {
          key: "ip",
          label: "IP",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "device_id",
          label: "Thiết bị",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "detail",
          label: "Chi tiết",
          tdClass: "datatable-cell",
          thClass: "datatable-cell"
        }
        // "action",
        // "coupon_code",
        // "description",
        // "platform",
        // "sender_id",
        // "sender_type",
        // "status",
        // "created_at",
        // "completed_at",
        // "updated_at",
        // "user_id",
      ],
      items: [],
      currentPage: 1,
      currentPage1: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      searchByV1: false,
    };
  },
  components: {
    filterTransaction,
    filterTransactionV1
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách giao dịch", route: "list" }
    ]);
    this.filter = this.$route.query;
    this.filter.created_at_from =
      this.filter.created_at_from ?? this.firstDayOfMonth();
    this.filter.created_at_to =
      this.filter.created_at_to ?? this.lastDayOfMonth();
  },
  methods: {
    resetCurrent1: function () {
      this.currentPage1 = 1;
    },
    resetCurrent: function () {
      this.currentPage = 1;
    },
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.searchByV1 = this.searchByV1;
        params.page = !this.searchByV1 ? this.currentPage : this.currentPage1;
        if (!this.searchByV1) {
          params.limit = this.paginate.limit;
        } else {
          params.per_page = this.paginate.limit;
        }
        let response = await TransactionRepository.getList(params);
        if (response.data.error_code) {
          this.notifyAlert("warn", response.data.message);
        }
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data.list) {
          body.data.list.forEach((value) => {
            if (value.code) {
              value["detail"] =
                "/#/transactions/detail?transaction_id=" + value.code;
            } else {
              value["detail"] =
                "/#/transactions/detail?wallet_transaction_id=" +
                value.wallet_transaction_id;
            }
          });
          this.items = body.data.list;
          // this.currentPage = body.data.paging.current
          this.paginate.total = body.data.paging.total;
          this.paginate.totalPage = body.data.paging.total_page;
          this.totalAmount = body.data.paging.totalAmount;
          this.totalPrice = body.data.paging.totalPrice;
        } else {
          this.items = [];
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    handleSearchByV1: function (newVal) {
      this.filter = {};
      this.items = [];
      // this.currentPage = 1;
      this.paginate.totalPage = 1;
      this.paginate.total = 0;
      this.paginate.page = 1;
      this.totalPrice = 0;
      this.totalAmount = 0;
      this.searchByV1 = newVal.param;
      if (this.searchByV1) {
        this.filter.date_from = this.firstDayOfMonth();
        this.filter.date_to = this.lastDayOfMonth();
      } else {
        this.filter.created_at_from = this.firstDayOfMonth();
        this.filter.created_at_to = this.lastDayOfMonth();
      }
      router
        .push({ path: "/transactions/list", query: this.filter })
        .catch(() => {});
    },
  },
  watch: {
    currentPage1() {
      this.getList();
    },
    currentPage() {
      this.getList();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
input[type="text"],
input[type="date"],
select {
  min-height: 43px;
}
</style>
